import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-body" }
const _hoisted_3 = { class: "card-footer" }
const _hoisted_4 = { class: "container" }
const _hoisted_5 = { class: "row text-center" }
const _hoisted_6 = { class: "col-sm-12 mb-15" }
const _hoisted_7 = { class: "text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vc_edit_policy_first_layer = _resolveComponent("vc-edit-policy-first-layer")!
  const _component_vc_edit_policy_second_layer = _resolveComponent("vc-edit-policy-second-layer")!
  const _component_vc_edit_policy_group_layer = _resolveComponent("vc-edit-policy-group-layer")!
  const _component_vc_edit_policy_credit_debit_layer = _resolveComponent("vc-edit-policy-credit-debit-layer")!
  const _component_vc_edit_policy_update_documents_layer = _resolveComponent("vc-edit-policy-update-documents-layer")!
  const _component_vc_edit_policy_amend_documents_layer = _resolveComponent("vc-edit-policy-amend-documents-layer")!
  const _component_vc_edit_policy_email_activity_layer = _resolveComponent("vc-edit-policy-email-activity-layer")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_vc_edit_policy_first_layer),
        _createVNode(_component_el_form, {
          ref: "policyRuleFormRef",
          model: _ctx.policy,
          "status-icon": "",
          rules: _ctx.rules,
          "scroll-to-error": "",
          id: "EditPolicyForm"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_vc_edit_policy_second_layer, {
                ref: "policyRef",
                "is-disable-all-fields": true
              }, null, 512),
              (_ctx.policy && _ctx.policy.is_group == 1)
                ? (_openBlock(), _createBlock(_component_vc_edit_policy_group_layer, {
                    key: 0,
                    ref: "policyGroupRef",
                    onReValidate: _ctx.revalidate,
                    "is-disable-all-fields": true
                  }, null, 8, ["onReValidate"]))
                : _createCommentVNode("", true),
              _createVNode(_component_vc_edit_policy_credit_debit_layer, {
                ref: "policyCDRef",
                "is-disable-all-fields": true
              }, null, 512),
              _createVNode(_component_vc_edit_policy_update_documents_layer, { "is-disable-all-fields": true }),
              (_ctx.policy && _ctx.policy.is_amended == 1)
                ? (_openBlock(), _createBlock(_component_vc_edit_policy_amend_documents_layer, {
                    key: 1,
                    "is-disable-all-fields": true
                  }))
                : _createCommentVNode("", true),
              (_ctx.policy && _ctx.email_activity && _ctx.email_activity.length > 0)
                ? (_openBlock(), _createBlock(_component_vc_edit_policy_email_activity_layer, {
                    key: 2,
                    "is-disable-all-fields": true
                  }))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    (_ctx.policy && _ctx.policy.status != 4)
                      ? (_openBlock(), _createBlock(_component_router_link, {
                          key: 0,
                          to: { name: 'assignedPolicies' },
                          class: "btn btn-secondary me-3"
                        }, {
                          default: _withCtx(() => _cache[2] || (_cache[2] = [
                            _createTextVNode("Cancel")
                          ])),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.policy && _ctx.policy.status == 4)
                      ? (_openBlock(), _createBlock(_component_router_link, {
                          key: 1,
                          to: { name: 'completedPolicies' },
                          class: "btn btn-secondary me-3"
                        }, {
                          default: _withCtx(() => _cache[3] || (_cache[3] = [
                            _createTextVNode("Cancel")
                          ])),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
                  ])
                ])
              ])
            ])
          ]),
          _: 1
        }, 8, ["model", "rules"])
      ])
    ]),
    _createVNode(_component_el_dialog, {
      modelValue: _ctx.emiratesErrorModal,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.emiratesErrorModal) = $event)),
      "show-close": false,
      "close-on-click-modal": false,
      width: "500px",
      title: 'Emirates ID'
    }, {
      default: _withCtx(() => [
        _cache[4] || (_cache[4] = _createElementVNode("div", { class: "px-5" }, [
          _createElementVNode("p", null, " There was an issue in reading the Emirates ID document , kindly enter the data manually ")
        ], -1)),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("button", {
            class: "btn btn-lg btn-info",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.emiratesErrorModal = false))
          }, "Ok")
        ])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}